/* Typekit */

/*
 * The Typekit service used to deliver this font or fonts for use on websites
 * is provided by Adobe and is subject to these Terms of Use
 * http://www.adobe.com/products/eulas/tou_typekit. For font license
 * information, see the list below.
 *
 * freight-big-pro:
 *   - http://typekit.com/eulas/0000000000000000000132c1
 *   - http://typekit.com/eulas/0000000000000000000132c2
 *   - http://typekit.com/eulas/0000000000000000000132c3
 *   - http://typekit.com/eulas/0000000000000000000132c4
 *   - http://typekit.com/eulas/0000000000000000000132c5
 *   - http://typekit.com/eulas/0000000000000000000132c6
 *   - http://typekit.com/eulas/0000000000000000000132c7
 *   - http://typekit.com/eulas/0000000000000000000132c8
 *
 * © 2009-2020 Adobe Systems Incorporated. All Rights Reserved.
 */
/*{"last_published":"2019-12-12 11:50:13 UTC"}*/

@font-face {
  font-family: "freight-big-pro";
  src: url("https://use.typekit.net/af/8de680/0000000000000000000132c1/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3")
      format("woff2"),
    url("https://use.typekit.net/af/8de680/0000000000000000000132c1/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3")
      format("woff"),
    url("https://use.typekit.net/af/8de680/0000000000000000000132c1/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3")
      format("opentype");
  font-display: swap;
  font-style: normal;
  font-weight: 700;
}

@font-face {
  font-family: "freight-big-pro";
  src: url("https://use.typekit.net/af/486b15/0000000000000000000132c2/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i7&v=3")
      format("woff2"),
    url("https://use.typekit.net/af/486b15/0000000000000000000132c2/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i7&v=3")
      format("woff"),
    url("https://use.typekit.net/af/486b15/0000000000000000000132c2/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i7&v=3")
      format("opentype");
  font-display: swap;
  font-style: italic;
  font-weight: 700;
}

@font-face {
  font-family: "freight-big-pro";
  src: url("https://use.typekit.net/af/ca7ae5/0000000000000000000132c3/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3")
      format("woff2"),
    url("https://use.typekit.net/af/ca7ae5/0000000000000000000132c3/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3")
      format("woff"),
    url("https://use.typekit.net/af/ca7ae5/0000000000000000000132c3/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3")
      format("opentype");
  font-display: swap;
  font-style: normal;
  font-weight: 400;
}

@font-face {
  font-family: "freight-big-pro";
  src: url("https://use.typekit.net/af/0203fc/0000000000000000000132c4/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3")
      format("woff2"),
    url("https://use.typekit.net/af/0203fc/0000000000000000000132c4/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3")
      format("woff"),
    url("https://use.typekit.net/af/0203fc/0000000000000000000132c4/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3")
      format("opentype");
  font-display: swap;
  font-style: italic;
  font-weight: 400;
}

@font-face {
  font-family: "freight-big-pro";
  src: url("https://use.typekit.net/af/daa52e/0000000000000000000132c5/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n3&v=3")
      format("woff2"),
    url("https://use.typekit.net/af/daa52e/0000000000000000000132c5/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n3&v=3")
      format("woff"),
    url("https://use.typekit.net/af/daa52e/0000000000000000000132c5/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n3&v=3")
      format("opentype");
  font-display: swap;
  font-style: normal;
  font-weight: 300;
}

@font-face {
  font-family: "freight-big-pro";
  src: url("https://use.typekit.net/af/8054ef/0000000000000000000132c6/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i3&v=3")
      format("woff2"),
    url("https://use.typekit.net/af/8054ef/0000000000000000000132c6/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i3&v=3")
      format("woff"),
    url("https://use.typekit.net/af/8054ef/0000000000000000000132c6/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i3&v=3")
      format("opentype");
  font-display: swap;
  font-style: italic;
  font-weight: 300;
}

@font-face {
  font-family: "freight-big-pro";
  src: url("https://use.typekit.net/af/dcfc24/0000000000000000000132c7/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n5&v=3")
      format("woff2"),
    url("https://use.typekit.net/af/dcfc24/0000000000000000000132c7/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n5&v=3")
      format("woff"),
    url("https://use.typekit.net/af/dcfc24/0000000000000000000132c7/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n5&v=3")
      format("opentype");
  font-display: swap;
  font-style: normal;
  font-weight: 500;
}

@font-face {
  font-family: "freight-big-pro";
  src: url("https://use.typekit.net/af/ab011c/0000000000000000000132c8/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i5&v=3")
      format("woff2"),
    url("https://use.typekit.net/af/ab011c/0000000000000000000132c8/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i5&v=3")
      format("woff"),
    url("https://use.typekit.net/af/ab011c/0000000000000000000132c8/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i5&v=3")
      format("opentype");
  font-display: swap;
  font-style: italic;
  font-weight: 500;
}

.tk-freight-big-pro {
  font-family: "freight-big-pro", serif;
}

/* Gotham Thin */

@font-face {
  font-family: "Gotham";
  src: url(/_next/static/images/20dc0b4cc08722ffb82a6aadbb0677be.woff2) format("woff2"),
    url(/_next/static/images/46026815382c1ea474d3e18102258ddb.woff) format("woff");
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Gotham";
  src: url(/_next/static/images/1be037e6407907411225c850d7a83242.woff2) format("woff2"),
    url(/_next/static/images/3f9eede2f4b41ba9f21667b71fda3906.woff) format("woff");
  font-weight: 100;
  font-style: italic;
  font-display: swap;
}

/* Gotham Extra Light */

@font-face {
  font-family: "Gotham";
  src: url(/_next/static/images/1e57d86d76049e241ed6877f0583b05d.woff2) format("woff2"),
    url(/_next/static/images/19fc5cc966660935495288617fd072db.woff) format("woff");
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Gotham";
  src: url(/_next/static/images/a53bf3cd4207df080d925ef9a70d474a.woff2) format("woff2"),
    url(/_next/static/images/2e12909699d1cdf31cd99e662d703ecf.woff) format("woff");
  font-weight: 200;
  font-style: italic;
  font-display: swap;
}

/* Gotham Light */

@font-face {
  font-family: "Gotham";
  src: url(/_next/static/images/c0b3ffc2d9843652d262385560485a89.woff2) format("woff2"),
    url(/_next/static/images/3d018f99885a21759ff33d0e8907972b.woff) format("woff");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Gotham";
  src: url(/_next/static/images/3f7a388a23d1e03c598afd0f2f1b14e2.woff2) format("woff2"),
    url(/_next/static/images/7681126e92d7b15aeef5621806932d8d.woff) format("woff");
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}

/* Gotham Book */

@font-face {
  font-family: "Gotham";
  src: url(/_next/static/images/3a8663e5d1eec054cfbd8666e95339de.woff2) format("woff2"),
    url(/_next/static/images/5acb720348428cc6e62a9ad2227e6928.woff) format("woff");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Gotham";
  src: url(/_next/static/images/b367d85b7aa1683f63ddfe4222095942.woff2) format("woff2"),
    url(/_next/static/images/315e1c54d26e867616a89bac4a02a066.woff) format("woff");
  font-weight: 400;
  font-style: italic;
  font-display: swap;
}

/* Gotham Medium */

@font-face {
  font-family: "Gotham";
  src: url(/_next/static/images/f62c7ae0b39b951a0c4140125c3817d1.woff2) format("woff2"),
    url(/_next/static/images/79ee9d36d8f90739ae3cb9ec52348afe.woff) format("woff");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Gotham";
  src: url(/_next/static/images/3afe6883000bab44ff7f473e0a15592d.woff2) format("woff2"),
    url(/_next/static/images/b0313de619a9c133575c937a41db71c2.woff) format("woff");
  font-weight: 500;
  font-style: italic;
  font-display: swap;
}

/* Gotham Bold */

@font-face {
  font-family: "Gotham";
  src: url(/_next/static/images/dd4e2cfe0206e2186eb0c5121d5233e5.woff2) format("woff2"),
    url(/_next/static/images/bc4295ef0979ac9505a6461202e7e3ac.woff) format("woff");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Gotham";
  src: url(/_next/static/images/cfe9bfb4c845ac792a657842a35b18c2.woff2) format("woff2"),
    url(/_next/static/images/2bc7b93715004bb0a4d44d8f253342f7.woff) format("woff");
  font-weight: 600;
  font-style: italic;
  font-display: swap;
}

/* Gotham Black */

@font-face {
  font-family: "Gotham";
  src: url(/_next/static/images/233b60e3d7138e731576d85a80143009.woff2) format("woff2"),
    url(/_next/static/images/915015358b6444f2ac52437a4cf64f12.woff) format("woff");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Gotham";
  src: url(/_next/static/images/9dc53356e4c47e9ff2f5eb4b6152dc02.woff2) format("woff2"),
    url(/_next/static/images/7b1f15b549f4ea1b3879c81107d0def6.woff) format("woff");
  font-weight: 700;
  font-style: italic;
  font-display: swap;
}

/* Gotham Ultra Bold */

@font-face {
  font-family: "Gotham";
  src: url(/_next/static/images/d3afe9d8934acfea1fe9e6b2b3ebb665.woff2) format("woff2"),
    url(/_next/static/images/bfac2590c157a9714fcb52bed19253ba.woff) format("woff");
  font-weight: 800;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Gotham";
  src: url(/_next/static/images/7502eca9100981ef2ac5cb7d5285f3a4.woff2) format("woff2"),
    url(/_next/static/images/1e8ed7ab3f2436661989af308c805245.woff) format("woff");
  font-weight: 800;
  font-style: italic;
  font-display: swap;
}

/* Styles */

* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
body {
  font-size: 12px;
  font-family: "Gotham";
  font-weight: 400;
  box-sizing: border-box;
  background: #fff;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  font-weight: 500;
}

blockquote,
p,
ol,
ul {
  font-size: 16px;
  letter-spacing: 0.06em;
  line-height: 1.4;
}
ol {
  padding: 0;
  list-style: none;
  counter-reset: counter;
}
ol li {
  counter-increment: counter;
  padding-bottom: 15px;
}
ol li::before {
  content: counter(counter) ". ";
  font-weight: 600;
  padding-right: 10px;
}

*[style*="font-family:freight-big-light"],
*[style*="font-family: freight-big-light"],
*[style*="font-family:freight-big-book"],
*[style*="font-family: freight-big-book"],
*[style*="font-family:freight-big-medium"],
*[style*="font-family: freight-big-medium"],
*[style*="font-family:freight-big-bold"],
*[style*="font-family: freight-big-bold"],
*[style*="font-family:freight-big-pro"],
*[style*="font-family: freight-big-pro"] {
  line-height: 0.85;
}

/* Since we can't add font-weight & letters-spacing selectors to react-draft-wysiwyg: */

*[style*="font-family:freight-big-pro"] em,
*[style*="font-family: freight-big-pro"] em,
*[style*="font-family:freight-big-light"] em,
*[style*="font-family: freight-big-light"] em,
*[style*="font-family:freight-big-book"] em,
*[style*="font-family: freight-big-book"] em,
*[style*="font-family:freight-big-medium"] em,
*[style*="font-family: freight-big-medium"] em,
*[style*="font-family:freight-big-bold"] em,
*[style*="font-family: freight-big-bold"] em {
  letter-spacing: 0.07em;
}

*[style*="font-family:freight-big-pro-uppercase"] em,
*[style*="font-family: freight-big-pro-uppercase"] em,
*[style*="font-family:freight-big-light-uppercase"] em,
*[style*="font-family: freight-big-light-uppercase"] em,
*[style*="font-family:freight-big-book-uppercase"] em,
*[style*="font-family: freight-big-book-uppercase"] em,
*[style*="font-family:freight-big-medium-uppercase"] em,
*[style*="font-family: freight-big-medium-uppercase"] em,
*[style*="font-family:freight-big-bold-uppercase"] em,
*[style*="font-family: freight-big-bold-uppercase"] em {
  letter-spacing: normal;
}

*[style*="font-family:freight-big-light"],
*[style*="font-family: freight-big-light"] {
  font-family: freight-big-pro !important;
  font-weight: 100;
  letter-spacing: normal;
}

*[style*="font-family:freight-big-light-uppercase"],
*[style*="font-family: freight-big-light-uppercase"] {
  font-family: freight-big-pro !important;
  font-weight: 100;
  text-transform: uppercase;
}

*[style*="font-family:freight-big-book"],
*[style*="font-family: freight-big-book"] {
  font-family: freight-big-pro !important;
  font-weight: 400;
  letter-spacing: normal;
}

*[style*="font-family:freight-big-book-uppercase"],
*[style*="font-family: freight-big-book-uppercase"] {
  font-family: freight-big-pro !important;
  font-weight: 400;
  text-transform: uppercase;
}

*[style*="font-family:freight-big-medium"],
*[style*="font-family: freight-big-medium"] {
  font-family: freight-big-pro !important;
  font-weight: 500;
  letter-spacing: normal;
}

*[style*="font-family:freight-big-medium-uppercase"],
*[style*="font-family: freight-big-medium-uppercase"] {
  font-family: freight-big-pro !important;
  font-weight: 500;
  text-transform: uppercase;
}

*[style*="font-family:freight-big-bold"],
*[style*="font-family: freight-big-bold"] {
  font-family: freight-big-pro !important;
  font-weight: 700;
  letter-spacing: normal;
}

*[style*="font-family:freight-big-bold-uppercase"],
*[style*="font-family: freight-big-bold-uppercase"] {
  font-family: freight-big-pro !important;
  font-weight: 700;
  text-transform: uppercase;
}

*[style*="font-family:Gotham-thin"],
*[style*="font-family: Gotham-thin"] {
  font-family: Gotham !important;
  font-weight: 300;
}

*[style*="font-family:Gotham-book"],
*[style*="font-family: Gotham-book"] {
  font-family: Gotham !important;
  font-weight: 400;
}

blockquote {
  background-image: url("/static/images/quotes.png");
  background-repeat: no-repeat;
  background-size: 100px;
  display: inline-block;
  padding: 2em;
  box-sizing: border-box;
}

@media screen and (max-width: 400px) {
  blockquote {
    padding: 2em 1em;
    width: 100%;
    margin: 0;
  }
}

/* Lazy loading */

img.lazyload,
img.lazyloading,
img.lazyloaded {
  opacity: 0;
  transition: opacity 400ms;
}

img.lazyloaded {
  opacity: 1;
}
